import logo from './Branding/mbakerfont.png';
import mathluna from './Branding/mbaker.png';
import bannerlogo from './Branding/mbakerdude.png';
import dude from './Branding/mbaker.png';
import './App.css';
import { Fragment, useState } from 'react'
import { Dialog, Listbox, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarDaysIcon,
  CreditCardIcon,
  EllipsisVerticalIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  UserCircleIcon,
  XMarkIcon as XMarkIconMini,
  CurrencyDollarIcon,
} from '@heroicons/react/20/solid'
import { BellIcon, XMarkIcon as XMarkIconOutline } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import Swal from 'sweetalert2'; 

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function App() {

  const [linuQty, setLinuQty] = useState("");
  const [mc, setMc] = useState("");
  const [worth, setWorth] = useState("");
  const [qtyAbrev, setQtyAbrev] = useState("");
  const [mcAbrev, setMcAbrev] = useState("");

	const  handleChangeQty = (event) => {
		setLinuQty(event.target.value);
    let qabrev = Intl.NumberFormat('en-US', {
      notation: "compact",
      maximumFractionDigits: 1
    }).format(event.target.value);
    setQtyAbrev(qabrev);
	};

	const  handleChangeMc = (event) => {
		setMc(event.target.value);
    let mabrev = Intl.NumberFormat('en-US', {
      notation: "compact",
      maximumFractionDigits: 1
    }).format(event.target.value);
    setMcAbrev(mabrev);
	};

  const formatCash = Intl.NumberFormat('en-US', {
    notation: "compact",
    maximumFractionDigits: 1
  }).format(2500);

  const handleSubmit = (event) => {
    let timeout;
    const swal = Swal.fire({
      title: 'Stack Analyzer',
      text: 'MBAKER is calculating for you...',
      imageUrl: mathluna,
      imageWidth: 300,
      imageHeight: 300,
      allowOutsideClick: false,
      backdrop: 'rgba(0, 0, 0, 0.5)',
      didOpen: () => {
        Swal.showLoading();
      }
    });
    event.preventDefault();
    let quantity = 0;
    let marketcap = 0;
    let totalworth = 0;

    //huh

    console.log(parseInt(mc))
    console.log(parseInt(linuQty))

    totalworth = parseInt((mc/767088637)*linuQty)

    console.log(totalworth)

    timeout = setTimeout(function() {
      Swal.close();
      setWorth(totalworth);
    }, 4000)


  }


  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <>
      <main>
        <header className="relative isolate p-4 bg-yellow-200" >
          <div className="absolute inset-0 -z-10 overflow-hidden" aria-hidden="true">
            <div className="absolute right-16 top-full -mt-16 transform-gpu opacity-70 blur-3xl xl:left-1/2 xl:-ml-10">
              <div

              />
            </div>
          </div>
          <div className="absolute inset-0 -z-10 overflow-hidden" aria-hidden="true">
            <div className="absolute right-16 top-full -mt-16 transform-gpu opacity-70 blur-3xl xl:right-1/2 xl:-ml-80">
              <div

              />
            </div>
          </div>

          <div className="mx-auto max-w-7xl ">
            <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="flex items-center gap-x-6">
                <img
                  src={dude}
                  alt=""
                  className="w-1/5 flex-none rounded-full ring-4 ring-yellow-500 bg-white"
                />
                <h1>
                  <div className="font-face-header">                
                    <img
                    src={logo}
                    alt=""
                    />
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </header>

        <div className="mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <label htmlFor="email" className="block text-2xl font-large leading-6 text-black pb-8 pt-6 text-center font-bold">
            MASTER BAKER STACK ANALYZER
          </label>

          <div class="grid grid-cols-1 gap-8">
            <form onSubmit={handleSubmit}>
            <div className="rounded min-h-80 w bg-yellow-500 text-center" style={{border: "1px solid gray"}}>
              <div className="p-4">
                <label htmlFor="email" className="block text-lg font-medium leading-6 text-white">
                  MBAKER QTY
                </label>
                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="linuqty"
                    id="linuqty"
                    value={linuQty}
                    onChange={handleChangeQty}
                    className="p-2 text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6"
                    placeholder="ex: 230184110689"
                    aria-describedby="linuqty"
                  />
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  {qtyAbrev}
                </div>
                </div>
                <p className="mt-2 text-sm text-gray-900" id="email-description">
                  enter total amount of MBAKER here with no commas, no decimals
                </p>
              </div>
              <div className="p-4">
                <label htmlFor="email" className="block text-lg font-medium leading-6 text-white">
                  PROJECTED MARKETCAP
                </label>
                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="mc"
                    id="mc"
                    value={mc}
                    onChange={handleChangeMc}
                    className="p-2 text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6"
                    placeholder="ex: 500000000"
                    aria-describedby="mc"
                  />
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    {mcAbrev}
                  </div>
                </div>
                <p className="mt-2 text-sm text-gray-900" id="email-description">
                  enter projected marketcap with no commas, no decimals
                </p>
              </div>
              <div className="p-4 text-center">
                <button
                  type="submit"
                  className="inline-flex items-center gap-x-2 rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  ANALYZE PROJECTED WEALTH
                  <CurrencyDollarIcon className="-mr-0.5 h-5 w-5 text-white" aria-hidden="true" />
                </button>
              </div>

            </div>
            </form>



            <div className="rounded min-h-80">
            { worth > 0 &&
             <div class="flex-col justify-center">
             <div style={{textAlign: "center", fontSize: 40}}>HOW DOES</div>
             <div style={{textAlign: "center", color:"green", fontSize: 50, fontWeight: "bold"}}>{USDollar.format(worth)}</div>
             <div style={{textAlign: "center", fontSize: 40}}>SOUND?</div>

              </div>              
            }
            </div>
          </div>               
        </div>
      </main>
    </>
  )
}

export default App;
